import { HeaderTheme } from "@components/site/Header";
import { SiteState } from "@components/site/SiteState";
import { ToolbarType } from "@components/site/Toolbar";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

export const BlockWrapper = ({ children, className, theme, toolbarState = "live-index", ignoreToolbar, label, triggerStart, triggerEnd, onTrigger }: { children: React.ReactNode; className?: string; theme?: HeaderTheme; toolbarState?: ToolbarType, ignoreToolbar?: boolean; label?: string; triggerStart?: string; triggerEnd?: string; onTrigger?: (a: ScrollTrigger) => void; }) => {
  const container = useRef<HTMLDivElement>(null)

  useGSAP(() => {
    // base scrolltrigger, used for toolbar and header theme
    if (!ignoreToolbar) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: "top top+=50",
          end: "bottom top+=50",
          scrub: true,
          // markers: true,
          preventOverlaps: true,
          onUpdate: (self) => {
            const set = theme || "light"
            if (self.progress > 0 && self.progress < 1) {
              if (SiteState.headerTheme !== set) {
                SiteState.headerTheme = set
              }
              if (toolbarState && SiteState.toolbarState !== toolbarState) {
                SiteState.toolbarState = toolbarState
              }
            }
            if (!triggerStart && !triggerEnd && onTrigger) {
              onTrigger(self)
            }
          }
        },
      })
    }
    // custom trigger, used for custom animations
    if (triggerStart || triggerEnd || onTrigger) {
      const t2 = gsap.timeline({
        scrollTrigger: {
          trigger: container.current,
          start: triggerStart || "top top+=50",
          end: triggerEnd || "bottom top+=50",
          scrub: true,
          // markers: true,
          preventOverlaps: true,
          onUpdate: (self) => {
            if (onTrigger) {
              onTrigger(self)
            }
          }
        },
      })
    }
  }, { scope: container })

  return (
    <div ref={container} className={twMerge("w-full relative", className)}>
      {children}
    </div>
  )
}